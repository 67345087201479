module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-apollo/gatsby-browser.js'),
      options: {"plugins":[],"uri":"https://admin.metallbau-wittberger.at/wp/graphql"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Metallbau Wittberger GmbH","short_name":"metallbau-wittberger","lang":"de","start_url":"/","display":"standalone","icon":"src/images/favicon.svg","legacy":false,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"9ce993c05972e20e05a1d7469a081f54"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
